import { createContext, useContext, useReducer } from 'react'
//* Create Context
const AuthContext = createContext()

/**
 * Method to get user from local storage and set it to state if it exists for  updating user state in auth context
 * @returns {object} initial state
 */
const initState = () => {
	let user = localStorage.getItem('currentUser')
		? JSON.parse(localStorage.getItem('currentUser')).user
		: ''
	let token = localStorage.getItem('currentUser')
		? JSON.parse(localStorage.getItem('currentUser')).token
		: ''
	return {
		isAuthenticated: user !== '' && token !== '' ? true : false,
		user: user,
		token: token,
	}
}

/**
 * Reducer method for useReducer hook
 * @param {object} state
 * @param {object} action
 * @returns {object} new state
 */
const authReducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN':
			return {
				isAuthenticated: true,
				user: action.payload.user,
				token: action.payload.token,
			}
		case 'LOGOUT':
			return {
				isAuthenticated: false,
				user: null,
				token: null,
			}
		case 'GET_USER':
			return {
				...state,
				user: action.payload.user,
			}
		default:
			return state
	}
}

/**
 *  Custom hook to use AuthContext
 * @returns {object} AuthContext
 */
export const useAuthContext = () => {
	const context = useContext(AuthContext)
	if (!context) {
		throw new Error('useAuth must be used within a AuthProvider')
	}
	return context
}

/**
 * Provider for AuthContext to wrap around the app
 * @param {*} props
 * @returns
 */
export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		authReducer,
		{
			isAuthenticated: false,
			user: null,
			token: null,
		},
		initState
	)
	return (
		<AuthContext.Provider value={{ ...state, dispatch }}>
			{children}
		</AuthContext.Provider>
	)
}
