import { Grid } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const AuthLayout = ({ children, title = 'title' }) => {
	return (
		<Grid
			container
			direction='column'
			alignItems='center'
			justifyContent='center'
			sx={{
				minHeight: '100%',
				minWidth: '100%',
				padding: 4,
				backgroundColor: 'primary.dark',
			}}
		>
			<Grid
				item
				xs={3}
				sx={{
					backgroundColor: 'white',
					padding: 3,
					borderRadius: 2,
					width: { sm: 500 },
					boxShadow: '0px 5px 5px  rgba(0, 0, 0, 0.2)',
				}}
			>
				<Outlet />
			</Grid>
		</Grid>
	)
}
