import { RouterProvider } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthProvider'
import { router } from './router/AppRouter'
import { AppTheme } from './themes/AppTheme'

function App() {
	return (
		<AppTheme>
			<AuthProvider>
				<RouterProvider router={router} />
			</AuthProvider>
		</AppTheme>
	)
}

export default App
