import { Navigate } from 'react-router-dom'
import { PATHS_AUTH } from '../../../router/paths'

import {
	EmailVerificationView,
	LoginView,
	RegisterView,
	ResetPasswordView,
} from '../views'

export const AuthRoutes = [
	{
		path: '',
		element: <Navigate to={`${PATHS_AUTH.root}/${PATHS_AUTH.login}`} />,
	},
	{
		path: PATHS_AUTH.login,
		element: <LoginView />,
		children: [
			{
				path: '',
				element: <LoginView />,
			},
			{
				path: ':provider',
				element: <LoginView />,
			},
		],
	},
	{
		path: PATHS_AUTH.register,
		element: <RegisterView />,
	},
	{
		path: PATHS_AUTH.emailVerification,
		element: <EmailVerificationView />,
		children: [
			{
				path: '',
				element: <EmailVerificationView />,
			},
			{
				path: ':token',
				element: <EmailVerificationView />,
			},
		],
	},
	{
		path: PATHS_AUTH.resetPassword,
		element: <ResetPasswordView />,
		children: [
			{
				path: '',
				element: <ResetPasswordView />,
			},
			{
				path: ':token',
				element: <ResetPasswordView />,
			},
		],
	},
]
