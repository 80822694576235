import { LoadingButton } from '@mui/lab'
import {
	Alert,
	CircularProgress,
	Collapse,
	Grid,
	TextField,
	Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PATHS_AUTH } from '../../../router/paths'
import {
	apiSendVerificationEmail,
	apiVerifyEmail,
} from '../../../services/auth/AuthService'

const emailRules = {
	required: { value: true, message: 'Email is required' },
	pattern: {
		value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
		message: 'Email is invalid',
	},
}

export const EmailVerificationView = () => {
	//* Hook for navigating to other routes
	const navigate = useNavigate()

	//* Hook for getting location info
	const location = useLocation()

	//* state for set loading status
	const [loading, setloading] = useState(false)

	//* Hook for getting path params
	const { token } = useParams()

	//* State for  alert messanges
	const [alert, setAlert] = useState(
		location.state?.alert || {
			open: false,
			severity: 'error',
			message: 'Something went wrong',
		}
	)
	//* state for form, using react-hook-form
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({ mode: 'onChange' })

	//* Handle sendVerificationEmail form submit
	const handleSendVerificationEmail = async data => {
		try {
			if (isValid) {
				setloading(true)
				const res = await apiSendVerificationEmail(data.email)
				setloading(false)
				if (res.error) throw new Error(JSON.stringify(res))
				setAlert({
					open: true,
					severity: res.status,
					message: res.message,
				})
			} else {
				setAlert({ open: true, severity: 'error', message: 'Invalid form' })
			}
		} catch (ex) {
			const error = JSON.parse(ex.message)
			setAlert({
				open: true,
				severity: error.status,
				message: error.message,
			})
		}
	}
	//* Handle VerificationEmail
	const handleVerificationEmail = useCallback(async () => {
		try {
			if (token) {
				setAlert({
					open: true,
					severity: 'info',
					message: 'Verifying email...',
				})
				// axios call to verify email
				const res = await apiVerifyEmail(token)
				if (res.error) throw new Error(JSON.stringify(res))
				navigate(`${PATHS_AUTH.root}/${PATHS_AUTH.login}`, {
					replace: true,
					state: {
						alert: {
							open: true,
							severity: res.status,
							message: res.message,
						},
					},
				})
			}
		} catch (ex) {
			const error = JSON.parse(ex.message)
			setAlert({
				open: true,
				severity: error.status,
				message:
					'Unable to verify email address please resend verification link',
			})
		}
	}, [navigate, token])

	//* Fetch verification email on mount
	useEffect(() => {
		handleVerificationEmail()

		return () => {}
	}, [handleVerificationEmail])

	return (
		<>
			<Grid container alignItems='center'>
				<Typography component='h1' variant='h4' sx={{ mx: 'auto' }}>
					Verify your email
				</Typography>
			</Grid>
			{/* Alert message */}
			<Collapse in={alert.open}>
				<Alert
					onClose={() => setAlert({ ...alert, open: false })}
					variant='filled'
					severity={alert.severity}
					sx={{ mt: 2 }}
				>
					{alert.message}
				</Alert>
			</Collapse>
			{/* Send verification email form */}
			<form onSubmit={handleSubmit(handleSendVerificationEmail)}>
				<Grid container>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<Typography>
							Enter your user account's email address and we will send you a
							verification link.
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<TextField
							type='email'
							label='Email'
							{...register('email', emailRules)}
							error={!!errors.email}
							helperText={!!errors.email ? errors.email?.message : ''}
							placeholder='user@example.com'
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<LoadingButton
							type='submit'
							variant='contained'
							fullWidth
							loadingIndicator={
								<CircularProgress size={24} sx={{ color: 'primary.main' }} />
							}
							loading={loading}
						>
							Send verification email
						</LoadingButton>
					</Grid>
				</Grid>
			</form>
		</>
	)
}
