import { api } from '../../api/api'
import { handleErrors } from '../../helpers'

/**
 * Response of the API
 * @typedef {Object}  ObjectResponse
 * @property {Object} data - data from the request
 * @property {string} message - message from the request
 * @property {string} status - status  name from the request
 * @property {boolean} error - if the request has an error
 */

/**
 * Method that makes a request to the backend login endpoint for login a user with email and password,
 * can return an error object or an object with the requested data
 * @param {string} email
 * @param {string} password
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const apiLogin = async (email, password) => {
	try {
		const res = await api.post('/auth/login', { email, password })
		return res.data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 * Method that makes a request to the backend logout endpoint for logout a user,
 * can return an error object or an object with the requested data
 * @param {String} token
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const apiLogout = async token => {
	try {
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		}
		const res = await api.post('/auth/logout', {}, config)
		return res.data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 * Method that makes a request to the backend register endpoint for register a user with email and password,
 * can return an error object or an object with the requested data
 * @param {string} name
 * @param {string} email
 * @param {string} password
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const apiRegister = async (name, email, password) => {
	try {
		const res = await api.post('/auth/register', { name, email, password })
		return res.data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 * Method that makes a request to the backend send password reset link endpoint for sending the email woith the reset password link,
 * can return an error object or an object with the requested data
 * @param {string} email
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const apiSendResetPasswordEmail = async email => {
	try {
		const res = await api.post('/auth/forgot-password', { email })
		return res.data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 * Method that makes a request to the backend reset password endpoint for reset old password,
 * which can return an error object or an object with the requested data
 * @param {string} token
 * @param {string} password
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const apiResetPassword = async (token, password) => {
	try {
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		}
		const res = await api.post('/auth/reset-password', { password }, config)
		return res.data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 * Method that makes a request to the backend send email verificarion link endpoint for sending the email with the verification link,
 * can return an error object or an object with the requested data
 * @param {string} email
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const apiSendVerificationEmail = async email => {
	try {
		const res = await api.post('/auth/resend-verification-email', { email })
		return res.data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 *  Method that makes a request to the backend email  verification  endpoint for verify the email,
 *  which can return an error object or an object with the requested data
 * @param {String} email
 * @param {String} token
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const apiVerifyEmail = async token => {
	try {
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		}
		const res = await api.post('/auth/verify-email', {}, config)
		return res.data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 * Method that makes a request to the backend user endpoint for gettting the user data using the token,
 * can return an error object or an object user based on the token provided by AuthContext
 * @param {String} token
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const apiGetUser = async token => {
	try {
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		}
		const { data } = await api.get('/auth/user', config)

		return data
	} catch (error) {
		console.error(error.message)
		return {
			data: null,
			status: 'error',
			error: true,
			message: error.message,
		}
	}
}
