import {
	Google,
	GitHub,
	Facebook,
	Apps,
	Twitter,
	LinkedIn,
} from '@mui/icons-material'
export const ProviderIcon = ({ provider, size = '2rem' }) => {
	switch (provider) {
		case 'google':
			return (
				<Google
					sx={{
						fontSize: size,
					}}
				/>
			)
		case 'facebook':
			return (
				<Facebook
					sx={{
						fontSize: size,
					}}
				/>
			)
		case 'twitter':
			return (
				<Twitter
					sx={{
						fontSize: size,
					}}
				/>
			)
		case 'linkedin':
			return (
				<LinkedIn
					sx={{
						fontSize: size,
					}}
				/>
			)
		case 'github':
			return (
				<GitHub
					sx={{
						fontSize: size,
					}}
				/>
			)
		default:
			return (
				<Apps
					sx={{
						fontSize: size,
					}}
				/>
			)
	}
}
