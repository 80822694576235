import { useAuthContext } from '../contexts/AuthProvider'
import { apiLogout } from '../services/auth'

/**
 *  Custom hook for logout
 * @returns {object} {logOut}
 */
export const useLogOut = () => {
	//* Get the AuthContext
	const { token, dispatch } = useAuthContext()
	/**
	 *  Method to log out the user and remove the user and token from the context state and local storage
	 * @returns {Object} response
	 */
	const logOut = async () => {
		const res = await apiLogout(token)
		if (res && !res.error) {
			localStorage.removeItem('currentUser')
			dispatch({
				type: 'LOGOUT',
			})
			return res
		}
		return res
	}
	return { logOut }
}
