import { createBrowserRouter, Navigate } from 'react-router-dom'
import { MainLayout } from '../layouts/MainLayout'
import { AuthLayout } from '../modules/auth/layouts/AuthLayout'
import { AuthRoutes } from '../modules/auth/routes'
import { HomeView } from '../views'
import { AuthGuard, GuestGuard } from './guards'
import { PATHS_AUTH, PATHS_DASHBOARD } from './paths'

export const router = createBrowserRouter([
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{
				path: '/',
				element: <Navigate to={PATHS_DASHBOARD.root} />,
			},
			{
				path: PATHS_DASHBOARD.root,
				element: (
					<AuthGuard>
						<HomeView />
					</AuthGuard>
				),
			},
			{
				path: `${PATHS_AUTH.root}`,
				element: (
					<GuestGuard>
						<AuthLayout />
					</GuestGuard>
				),
				children: AuthRoutes,
			},
			{
				path: '*',
				element: <div>404</div>,
			},
		],
	},
])
