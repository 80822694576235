import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
	Alert,
	CircularProgress,
	Collapse,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { PATHS_AUTH } from '../../../router/paths'
import {
	apiResetPassword,
	apiSendResetPasswordEmail,
} from '../../../services/auth/AuthService'

const emailRules = {
	required: { value: true, message: 'Email is required' },
	pattern: {
		value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
		message: 'Email is invalid',
	},
}

const passwordRules = {
	required: { value: true, message: 'Password is required' },
	minLength: { value: 8, message: 'Password must be at least 8 characters' },
	pattern: {
		value:
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
		message:
			'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
	},
}

export const ResetPasswordView = () => {
	//* Hook for navigating to other routes
	const navigate = useNavigate()

	//* Hook for getting path params
	const { token } = useParams()

	//* State for loading status
	const [loading, setloading] = useState(false)

	//* State for  alert messanges
	const [alert, setAlert] = useState({
		open: false,
		severity: 'error',
		message: 'Something went wrong',
	})

	//* State for password visibility
	const [showPassword, setShowPassword] = useState({
		password: false,
		confirmPassword: false,
	})

	//* state for form, using react-hook-form
	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm({ mode: 'onChange' })

	/**
	 * * Hanlde sendVerificationEmail form submit using axios
	 * @param {object} data - form data
	 */
	const handlePasswordResetEmail = async data => {
		try {
			if (isValid) {
				setloading(true)
				// axios response
				const res = await apiSendResetPasswordEmail(data.email)
				if (res.error) throw new Error(JSON.stringify(res))
				setloading(false)
				setAlert({
					open: true,
					severity: res.status,
					message: res.message,
				})
			} else {
				setAlert({ open: true, severity: 'error', message: 'Invalid form' })
			}
		} catch (ex) {
			const error = JSON.parse(ex.message)
			setAlert({ open: true, severity: error.status, message: error.message })
		}
	}

	/**
	 *  * Handle reset password form submit using axios
	 *  @param {object} data - form data
	 */
	const handlePasswordReset = async data => {
		try {
			if (isValid) {
				setloading(true)
				// axios response
				const res = await apiResetPassword(token, data.password)
				setloading(false)
				// Throw error if error is true
				if (res.error) throw new Error(JSON.stringify(res))
				reset()

				// Navigate to login page with success message
				navigate(`${PATHS_AUTH.root}/${PATHS_AUTH.login}`, {
					replace: true,
					state: {
						alert: { open: true, severity: res.status, message: res.message },
					},
				})
			} else {
				setAlert({ open: true, severity: 'error', message: 'Invalid form' })
			}
		} catch (ex) {
			const error = JSON.parse(ex.message)
			setAlert({ open: true, severity: error.status, message: error.message })
		}
	}

	return (
		<>
			<Grid container alignItems='center'>
				<Typography component='h1' variant='h4' sx={{ mx: 'auto' }}>
					Reset your password
				</Typography>
			</Grid>
			{/* Alert message */}
			<Collapse in={alert.open}>
				<Alert
					onClose={() => setAlert({ ...alert, open: false })}
					variant='filled'
					severity={alert.severity}
					sx={{ mt: 2 }}
				>
					{alert.message}
				</Alert>
			</Collapse>
			{/* If token doesnt exist show form else show message */}
			{!token ? (
				// Send password reset email form
				<form onSubmit={handleSubmit(handlePasswordResetEmail)}>
					<Grid container>
						<Grid item xs={12} sx={{ marginTop: 2 }}>
							<Typography>
								Enter your user account's verified email address and we will
								send you a password reset link.
							</Typography>
						</Grid>
						<Grid item xs={12} sx={{ marginTop: 2 }}>
							<TextField
								type='email'
								label='Email'
								{...register('email', emailRules)}
								error={!!errors.email}
								helperText={!!errors.email ? errors.email?.message : ''}
								placeholder='user@example.com'
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sx={{ marginTop: 2 }}>
							<LoadingButton
								type='submit'
								variant='contained'
								fullWidth
								loadingIndicator={
									<CircularProgress size={24} sx={{ color: 'primary.main' }} />
								}
								loading={loading}
							>
								Send password reset email
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			) : (
				// Password reset form
				<form onSubmit={handleSubmit(handlePasswordReset)}>
					<Grid container>
						<Grid item xs={12} sx={{ marginTop: 2 }}>
							<Typography>
								Enter your new password and confirm it to reset your password.
							</Typography>
						</Grid>
						<Grid item xs={12} sx={{ marginTop: 2 }}>
							<TextField
								type={showPassword.password ? 'text' : 'password'}
								label='Password'
								{...register('password', passwordRules)}
								error={!!errors.password}
								helperText={!!errors.password ? errors.password?.message : ''}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												edge='end'
												onClick={() =>
													setShowPassword({
														...showPassword,
														password: !showPassword.password,
													})
												}
											>
												{showPassword.password ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sx={{ marginTop: 2 }}>
							<TextField
								type={showPassword.confirmPassword ? 'text' : 'password'}
								label='Password'
								{...register('confirmPassword', {
									required: {
										value: true,
										message: 'Confirm password is required',
									},
									validate: value => {
										if (value === watch('password')) return true
										else return 'Passwords do not match'
									},
								})}
								error={!!errors.confirmPassword}
								helperText={
									!!errors.confirmPassword
										? errors.confirmPassword?.message
										: ''
								}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												edge='end'
												onClick={() =>
													setShowPassword({
														...showPassword,
														confirmPassword: !showPassword.confirmPassword,
													})
												}
											>
												{showPassword.confirmPassword ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} sx={{ marginTop: 2 }}>
							<LoadingButton
								type='submit'
								variant='contained'
								fullWidth
								loadingIndicator={
									<CircularProgress size={24} sx={{ color: 'primary.main' }} />
								}
								loading={loading}
							>
								Reset password
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			)}
		</>
	)
}
