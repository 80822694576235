import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../../contexts/AuthProvider'
import { PATHS_AUTH } from '../paths'

export const AuthGuard = ({ children }) => {
	const { isAuthenticated } = useAuthContext()
	return isAuthenticated ? (
		children
	) : (
		<Navigate to={`${PATHS_AUTH.root}/${PATHS_AUTH.login}`} />
	)
}
