import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
	Alert,
	Backdrop,
	Checkbox,
	CircularProgress,
	Collapse,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { useLogIn } from '../../../hooks/useLogIn'
import { PATHS_AUTH } from '../../../router/paths'
import { ProviderButton } from '../components'

const emailRules = {
	required: { value: true, message: 'Email is required' },
	pattern: {
		value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
		message: 'Email is invalid',
	},
}
const passwordRules = {
	required: { value: true, message: 'Password is required' },
}
export const LoginView = () => {
	//* Hook for getting location info
	const location = useLocation()

	//* Hook for getting path params
	const { provider } = useParams()

	//* state for set loading status
	const [loading, setloading] = useState(false)

	//* State for  loading providers
	const [loadingProvider, setLoadingProvider] = useState(false)

	//* State for password visibility
	const [showPassword, setShowPassword] = useState(false)

	//* State for  alert messanges
	const [alert, setAlert] = useState(
		location.state?.alert || {
			open: false,
			severity: 'error',
			message: 'Something went wrong',
		}
	)

	//* state for form, using react-hook-form
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({ mode: 'onChange' })

	//* Get the logIn method from the custom hook
	const { logIn, providerLogIn } = useLogIn()

	/**
	 *  Function to handle login form submit event, calls the login function provided by the auth context and handle the response
	 * @param {object} data - data from the form
	 */
	const handleLogin = async data => {
		try {
			if (isValid) {
				setloading(true)
				const res = await logIn(data.email, data.password, data.remember)
				setloading(false)
				if (res.error) throw new Error(JSON.stringify(res))
			} else
				setAlert({ open: true, severity: 'error', message: 'Invalid form' })
		} catch (ex) {
			const error = JSON.parse(ex.message)
			setAlert({ open: true, severity: error.status, message: error.message })
		}
	}

	//* Function to handle the message alert returned by the provider button component event
	const handleRedirectProvider = res => {
		setAlert({ open: true, severity: res.status, message: res.message })
	}

	//* Function to handle the provider callback
	const handleLoginProvider = useCallback(async () => {
		try {
			if (provider && location.search !== '') {
				setLoadingProvider(true)
				const res = await providerLogIn(provider, location.search)
				setLoadingProvider(false)
				if (res.error) throw new Error(JSON.stringify(res))
			}
		} catch (ex) {
			const error = JSON.parse(ex.message)
			setAlert({ open: true, severity: error.status, message: error.message })
		}
	}, [providerLogIn, provider, location.search])

	//* Fetch login with provider on mount
	useEffect(() => {
		handleLoginProvider()
		return () => {}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{/* Backdrop loading while getting providers or logging in with provider */}
			<Backdrop
				open={loadingProvider}
				sx={{
					color: 'primary.main',
					backgroundColor: 'background.default',
					zIndex: theme => theme.zIndex.drawer + 1,
				}}
			>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Grid container alignItems='center'>
				<Typography component='h1' variant='h4' sx={{ mx: 'auto' }}>
					Login
				</Typography>
			</Grid>
			{/* Alert message */}
			<Collapse in={alert.open}>
				<Alert
					onClose={() => setAlert({ ...alert, open: false })}
					variant='filled'
					severity={alert.severity}
					sx={{ mt: 2 }}
				>
					{alert.message}
				</Alert>
			</Collapse>
			{/* Form */}
			<form onSubmit={handleSubmit(handleLogin)}>
				<Grid container>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<TextField
							type='email'
							label='Email'
							{...register('email', emailRules)}
							error={!!errors.email}
							helperText={!!errors.email ? errors.email?.message : ''}
							placeholder='user@example.com'
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<TextField
							type={showPassword ? 'text' : 'password'}
							label='Password'
							{...register('password', passwordRules)}
							error={!!errors.password}
							helperText={!!errors.password ? errors.password?.message : ''}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											edge='end'
											onClick={() => setShowPassword(!showPassword)}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<FormGroup sx={{ mt: 2 }}>
						<FormControlLabel
							control={<Checkbox {...register('remember')} defaultChecked />}
							label='Remember Me'
						/>
					</FormGroup>
				</Grid>
				<Grid container spacing={2} sx={{ mt: 1 }}>
					<Grid item xs={12}>
						<LoadingButton
							type='submit'
							variant='contained'
							fullWidth
							loadingIndicator={
								<CircularProgress size={24} sx={{ color: 'primary.main' }} />
							}
							loading={loading}
						>
							Log In
						</LoadingButton>
						{/* Provider Buttons */}
						<ProviderButton
							onRedirect={handleRedirectProvider}
							dividerMsg='or login with'
						/>
					</Grid>
					<Grid item xs={12}></Grid>
					<Grid container justifyContent='space-around' sx={{ marginTop: 3 }}>
						<Link
							color='inherit'
							component={RouterLink}
							to={`${PATHS_AUTH.root}/${PATHS_AUTH.resetPassword}`}
						>
							Forgot password?
						</Link>

						<Link
							color='inherit'
							component={RouterLink}
							to={`${PATHS_AUTH.root}/${PATHS_AUTH.register}`}
						>
							You do not have an account? Sign up
						</Link>
					</Grid>
				</Grid>
			</form>
		</>
	)
}
