import { api } from '../../api/api'
import { handleErrors } from '../../helpers'

/**
 * Response of the API
 * @typedef {Object}  ObjectResponse
 * @property {Object} data - data from the request
 * @property {string} message - message from the request
 * @property {string} status - status  name from the request
 * @property {boolean} error - if the request has an error
 */

/**
 * Method that makes a request to the backend provider redirect endpoint to get the url to redirect the user to the provider login page,
 *  which can return an error object or an object with the requested data (provider url)
 * @param {string} provider - provider name
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const providerRedirect = async provider => {
	try {
		const { data } = await api.get(`/auth/${provider}/redirect`)
		return data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 * Method that makes a request to the backend provider callback endpoint to create a user with the provider data,
 * can return an error object or an object with the requested data (user & token object)
 * @param {string} provider - provider name
 * @param {string} query - query params generated by the provider
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const providerCallBack = async (provider, query) => {
	try {
		const { data } = await api.get(`/auth/${provider}/callback${query}`)
		return data
	} catch (error) {
		return handleErrors(error)
	}
}

/**
 * Method that makes a request to the backend get providers endpoint to get the providers,
 * if active is 1, it will return only the active providers,
 * if active is 0, it will return only the inactive providers,
 * if active is null, it will return all the providers,
 * can return an error object or an object with the requested data (providers array)
 * @param {0 | 1 | null} active - active status of the providers
 * @returns {Promise<ObjectResponse>} Response object with the requested data
 */
export const getProviders = async (active = null) => {
	try {
		const { data } = await api.get(`/auth/providers/${active}`)
		return data
	} catch (error) {
		return handleErrors(error)
	}
}
