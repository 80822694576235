import { CssBaseline, ThemeProvider } from '@mui/material'
import { defaultTheme } from './'

export const AppTheme = ({ children }) => {
	return (
		<ThemeProvider theme={defaultTheme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	)
}
