const ROOT_AUTH = '/auth'

/**
 * Since the router is working in modules and the child routes already have a parent route,
 * it is not necessary to add the prefix of the parent route to each route.
 */
export const PATHS_AUTH = {
	root: ROOT_AUTH,
	login: `login`,
	register: `register`,
	resetPassword: `reset-password`,
	emailVerification: `email-verification`,
}

export const PATHS_DASHBOARD = {
	root: '/dashboard',
}
