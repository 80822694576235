import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthProvider'
import { apiLogin, providerCallBack } from '../services/auth'
/**
 * Response of the API
 * @typedef {Object}  ObjectResponse
 * @property {Object} data - data from the request
 * @property {string} message - message from the request
 * @property {string} status - status  name from the request
 * @property {boolean} error - if the request has an error
 */

/**
 *  Custom hook for login
 * @returns {object} {logIn, providerLogIn}
 */
export const useLogIn = () => {
	//* Get the AuthContext
	const { dispatch } = useAuthContext()
	//* Get the navigate method from react-router-dom
	const navigate = useNavigate()
	/**
	 * Method to log in the user and set the user and token in the context state and local storage if the user checks the remember me checkbox
	 * @param {String} email
	 * @param {String} password
	 * @param {boolean} remember
	 * @returns {Promise<ObjectResponse>} Response object with the requested data
	 */
	const logIn = async (email, password, remember) => {
		const res = await apiLogin(email, password)
		if (res && !res.error) {
			if (remember) {
				localStorage.setItem(
					'currentUser',
					JSON.stringify({
						user: res.data.user,
						token: res.data.token,
					})
				)
			}
			dispatch({
				type: 'LOGIN',
				payload: {
					user: res.data.user,
					token: res.data.token,
				},
			})
			navigate('/')
			return res
		}
		return res
	}

	/**
	 *  Method to log in the user with a third party provider and set the user and token in the context state and local storage
	 * @param {string} provider - The provider name
	 * @param {string} query - Query params generated by the provider
	 * @returns {Promise<ObjectResponse>} Response object with the requested data
	 */
	const providerLogIn = async (provider, query) => {
		const res = await providerCallBack(provider, query)
		if (res && !res.error) {
			localStorage.setItem(
				'currentUser',
				JSON.stringify({
					user: res.data.user,
					token: res.data.token,
				})
			)
			dispatch({
				type: 'LOGIN',
				payload: {
					user: res.data.user,
					token: res.data.token,
				},
			})
			navigate('/')
			return res
		}
		return res
	}
	return { logIn, providerLogIn }
}
