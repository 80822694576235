import {
	Backdrop,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Skeleton,
	Tooltip,
} from '@mui/material'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getProviders, providerRedirect } from '../../../services/auth'
import { ProviderIcon } from './ProviderIcon'

export const ProviderButton = ({ onRedirect, dividerMsg = 'or' }) => {
	//* State to show the loading spinner when the user clicks on the provider button
	const [loading, setLoading] = useState(true)

	//* state for providers list
	const [providers, setProviders] = useState([])

	/**
	 * Method to redirect the user to the provider login page
	 * @param {string} provider
	 */
	const handleProviderClick = async provider => {
		try {
			setLoading(true)
			const res = await providerRedirect(provider)
			setLoading(false)
			if (res && res.error) throw new Error(JSON.stringify(res))
			if (res && res.data.url) {
				window.location.href = res.data.url
			}
		} catch (ex) {
			const error = JSON.parse(ex.message)
			onRedirect(error)
		}
	}

	/**
	 * Method to get the active providers list
	 */
	const handleProviderList = useCallback(async () => {
		try {
			setLoading(true)
			const res = await getProviders(1)
			setLoading(false)
			if (res && res.error) throw new Error(JSON.stringify(res))
			if (res && res.data) {
				setProviders(res.data.providers)
			}
		} catch (ex) {
			const error = JSON.parse(ex.message)
			console.error(error.message)
		}
	}, [])

	useEffect(() => {
		handleProviderList()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	if (providers.length === 0) {
		if (!loading) {
			return null
		}

		return (
			<Grid
				container
				justifyContent='center'
				alignItems='center'
				sx={{ my: 2 }}
			>
				<Skeleton
					variant='circular'
					width={10}
					height={10}
					sx={{ backgroundColor: 'primary.light', mx: 0.1 }}
				/>
				<Skeleton
					variant='circular'
					width={10}
					height={10}
					sx={{ backgroundColor: 'primary.light', mx: 0.1 }}
				/>
				<Skeleton
					variant='circular'
					width={10}
					height={10}
					sx={{ backgroundColor: 'primary.light', mx: 0.1 }}
				/>
			</Grid>
		)
	}
	return (
		<>
			<Backdrop
				sx={{
					color: 'primary.main',
					backgroundColor: 'background.default',
					zIndex: theme => theme.zIndex.drawer + 1,
				}}
				open={loading}
			>
				<CircularProgress color='inherit' />
			</Backdrop>

			<Divider sx={{ my: 2 }}>{dividerMsg}</Divider>
			<Grid container justifyContent='center' alignItems='center'>
				{providers.map(provider => (
					<Tooltip key={provider.id} title={provider.name} arrow={true}>
						<IconButton
							color='primary'
							onClick={() => handleProviderClick(provider.provider)}
						>
							<ProviderIcon provider={provider.provider} size='2rem' />
						</IconButton>
					</Tooltip>
				))}
			</Grid>
		</>
	)
}
