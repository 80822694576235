/**
 *Method that handles the error provided by the catch to return an object with the details of the error
 * @param {object} error - error object from catch block
 * @returns {{data:null,error:boolean, message: string, status: string}} object with the details of the error
 */
export const handleErrors = error => {
	const res = error.response?.data
	let message = ''
	if (res && res.errors)
		message = Object.values(res.errors).join(', ').replaceAll('.,', ', ')
	else if (res && res.message && typeof res.message === 'string')
		message = res.message
	else message = error.message
	console.error(message)
	return {
		data: null,
		error: true,
		message: message,
		status: 'error',
	}
}
