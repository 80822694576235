import { Grid, Typography } from '@mui/material'
import { useAuthContext } from '../contexts/AuthProvider'

export const HomeView = () => {
	const { user } = useAuthContext()
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant='h1'>Welcome</Typography>
				<Typography variant='h2'>{user?.name}</Typography>
			</Grid>
		</Grid>
	)
}
