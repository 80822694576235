import { Toolbar } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthProvider'
import { apiGetUser } from '../services/auth'
import { NavBar } from './components'

const navBarHeight = 64
export const MainLayout = () => {
	//* Get the AuthContext
	const { token, dispatch } = useAuthContext()

	//* Get the user of the token in the context state and verify if the token is valid
	useEffect(() => {
		const getUser = async () => {
			const res = await apiGetUser(token)
			if (res && !res.error) {
				dispatch({
					type: 'GET_USER',
					payload: {
						user: res.data.user,
					},
				})
				return res
			}
			localStorage.removeItem('currentUser')
			dispatch({
				type: 'LOGOUT',
			})
		}
		if (token) {
			getUser()
		}
	}, [token, dispatch])
	return (
		<Box sx={{ display: 'flex' }}>
			{/**Navbar */}
			<NavBar />
			{/**Main */}

			<Box
				sx={{
					padding: 0,
					minHeight: `calc(100vh - ${navBarHeight}px)`,
					minWidth: '100%',
				}}
			>
				<Toolbar />
				<Outlet />
			</Box>
		</Box>
	)
}
