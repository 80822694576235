import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
	Alert,
	Backdrop,
	CircularProgress,
	Collapse,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { PATHS_AUTH } from '../../../router/paths'
import { apiRegister } from '../../../services/auth/AuthService'
import { ProviderButton } from '../components'

const nameRules = {
	required: { value: true, message: 'Name is required' },
	minLength: { value: 3, message: 'Name must be at least 3 characters' },
	pattern: {
		value: /^[^\s].+[^\s]$/,
		message: 'No white spaces at the beginning or end',
	},
}

const emailRules = {
	required: { value: true, message: 'Email is required' },
	pattern: {
		value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
		message: 'Email is invalid',
	},
}
const passwordRules = {
	required: { value: true, message: 'Password is required' },
	minLength: { value: 8, message: 'Password must be at least 8 characters' },
	pattern: {
		value:
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
		message:
			'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
	},
}

export const RegisterView = () => {
	//* Hook for navigating to other routes
	const navigate = useNavigate()

	//* State for  alert messanges
	const [alert, setAlert] = useState({
		open: false,
		severity: 'error',
		message: 'Something went wrong',
	})

	//* State for password visibility
	const [showPassword, setShowPassword] = useState({
		password: false,
		confirmPassword: false,
	})

	//* state for loading status
	const [loading, setloading] = useState(false)

	//* state for form, using react-hook-form
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid },
	} = useForm({ mode: 'onChange' })

	//* Function to handle the message alert returned by the provider button component event
	const handleRedirectProvider = res => {
		setAlert({ open: true, severity: res.status, message: res.message })
	}

	/**
	 *  Function to handle login form submit event, calls the register function provided by auth service and handle the response
	 * @param {object} data - data from form
	 */
	const handleRegister = async data => {
		try {
			if (isValid) {
				setloading(true)
				// * Call register function from auth service
				const res = await apiRegister(data.name, data.email, data.password)
				setloading(false)
				if (res.error) throw new Error(JSON.stringify(res))
				navigate(`${PATHS_AUTH.root}/${PATHS_AUTH.emailVerification}`, {
					state: {
						alert: {
							open: true,
							severity: 'success',
							message:
								'Account created successfully, We sent you an email to verify your account please check your inbox. If you did not receive the email you can resend the verification email',
						},
					},
				})
			} else
				setAlert({ open: true, severity: 'error', message: 'Invalid form' })
		} catch (ex) {
			const error = JSON.parse(ex.message)
			setAlert({ open: true, severity: error.status, message: error.message })
		}
	}

	return (
		<>
			<Grid container alignItems='center'>
				<Typography component='h1' variant='h4' sx={{ mx: 'auto' }}>
					Register
				</Typography>
			</Grid>
			{/* Alert message */}
			<Collapse in={alert.open}>
				<Alert
					onClose={() => setAlert({ ...alert, open: false })}
					variant='filled'
					severity={alert.severity}
					sx={{ mt: 2 }}
				>
					{alert.message}
				</Alert>
			</Collapse>
			{/* Register form */}
			<form onSubmit={handleSubmit(handleRegister)}>
				<Grid container>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<TextField
							type='text'
							label='Name'
							{...register('name', nameRules)}
							error={!!errors.name}
							helperText={!!errors.name ? errors.name?.message : ''}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<TextField
							type='email'
							label='Email'
							{...register('email', emailRules)}
							error={!!errors.email}
							helperText={!!errors.email ? errors.email?.message : ''}
							placeholder='user@example.com'
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<TextField
							type={showPassword.password ? 'text' : 'password'}
							label='Password'
							{...register('password', passwordRules)}
							error={!!errors.password}
							helperText={!!errors.password ? errors.password?.message : ''}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											edge='end'
											onClick={() =>
												setShowPassword({
													...showPassword,
													password: !showPassword.password,
												})
											}
										>
											{showPassword.password ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: 2 }}>
						<TextField
							type={showPassword.confirmPassword ? 'text' : 'password'}
							label='Password'
							{...register('confirmPassword', {
								required: {
									value: true,
									message: 'Confirm password is required',
								},
								validate: value => {
									if (value === watch('password')) return true
									else return 'Passwords do not match'
								},
							})}
							error={!!errors.confirmPassword}
							helperText={
								!!errors.confirmPassword ? errors.confirmPassword?.message : ''
							}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											edge='end'
											onClick={() =>
												setShowPassword({
													...showPassword,
													confirmPassword: !showPassword.confirmPassword,
												})
											}
										>
											{showPassword.confirmPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid container spacing={2} sx={{ mt: 1 }}>
						<Grid item xs={12}>
							<LoadingButton
								type='submit'
								variant='contained'
								fullWidth
								loadingIndicator={
									<CircularProgress size={24} sx={{ color: 'primary.main' }} />
								}
								loading={loading}
							>
								Create Account
							</LoadingButton>
							{/* Provider Buttons */}
							<ProviderButton
								onRedirect={handleRedirectProvider}
								dividerMsg='or register with'
							/>
						</Grid>
						<Grid container justifyContent='end' sx={{ marginTop: 3 }}>
							<Link
								color='inherit'
								component={RouterLink}
								to={`${PATHS_AUTH.root}/${PATHS_AUTH.login}`}
							>
								You have an account? Log in
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</>
	)
}
