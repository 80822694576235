import { AppBar, Button, Toolbar, Typography } from '@mui/material'

import { Diversity2 } from '@mui/icons-material'
import { Link as ReactRouter } from 'react-router-dom'
import { PATHS_AUTH } from '../../router/paths'
import { useLogOut } from '../../hooks/useLogOut'
import { useAuthContext } from '../../contexts/AuthProvider'

export const NavBar = () => {
	//* Get the AuthContext
	const { isAuthenticated } = useAuthContext()

	//* Get the logOut method from the custom hook
	const { logOut } = useLogOut()

	//* Method to handle the click on the logout button
	const handleLogout = async () => {
		await logOut()
	}

	return (
		<AppBar position='fixed' sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
			<Toolbar>
				<Diversity2 sx={{ mr: 1 }} />
				<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
					HR - System
				</Typography>

				{!isAuthenticated ? (
					<>
						<Button
							variant='text'
							color='inherit'
							component={ReactRouter}
							to={`${PATHS_AUTH.root}/${PATHS_AUTH.login}`}
						>
							Login
						</Button>
						<Button
							variant='text'
							color='inherit'
							component={ReactRouter}
							to={`${PATHS_AUTH.root}/${PATHS_AUTH.register}`}
						>
							Register
						</Button>
					</>
				) : (
					<Button variant='text' color='inherit' onClick={handleLogout}>
						Logout
					</Button>
				)}
			</Toolbar>
		</AppBar>
	)
}
